html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  font-family: 'Open Sans';
}

* {
  box-sizing: border-box;
}

#root {
  overflow: auto;
}
